var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"hour-input",class:( _obj = {
    'hour-input-no-actions': _vm.loading
  }, _obj[_vm.roundClass] = true, _obj ),attrs:{"dark":_vm.dark,"color":_vm.color,"outlined":_vm.outlined,"flat":_vm.flat}},[(_vm.loading)?_c('div',{staticClass:"hour-input__loading"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"wb_color_primary__color__"}})],1):_vm._e(),(_vm.hours && _vm.hours.length > 0 && !_vm.loading)?_c('v-list',{attrs:{"dark":_vm.dark,"flat":_vm.flat,"color":_vm.color}},[_c('v-list-item-group',{attrs:{"color":_vm.color,"dark":_vm.dark},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},_vm._l((_vm.showBookedSlots ? _vm.hours : _vm.hours.filter((function (x) { return !x.booked; }))),function(item,i){
  var _obj;
return _c('v-sheet',{key:i,staticClass:"ma-2",class:( _obj = {}, _obj[_vm.roundClass] = true, _obj[_vm.color] = true, _obj['text-disabled'] =  _vm.showBookedSlots && item.booked, _obj['hour-wrapper'] =  !item.booked, _obj ),attrs:{"dark":_vm.dark,"disabled":_vm.showBookedSlots && item.booked,"outlined":""}},[_c('v-list-item',{staticClass:"text-center",attrs:{"dark":_vm.dark,"value":item.time,"disabled":_vm.showBookedSlots && item.booked}},[_c('v-list-item-content',[_c('v-list-item-title',{class:{
              'text-decoration-line-through text-disabled': _vm.showBookedSlots && item.booked
            }},[_vm._v(_vm._s(item.time)+" ")])],1),(_vm.bookingButton && item.time === _vm.activeHour)?_c('v-list-item-action',[_c('v-btn',{attrs:{"color":"wb_color_primary__color__","depressed":""},on:{"click":function($event){return _vm.$emit('book', _vm.activeHour)}}},[_vm._v(" "+_vm._s(_vm.bookNowLabel)+" ")])],1):_vm._e(),(!_vm.bookingButton && item.time === _vm.activeHour)?_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s('$check')}})],1):_vm._e()],1)],1)}),1)],1):(!_vm.loading)?_c('div',{staticClass:"hour-input__no-data"},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"text-center font-weight-medium"},[_c('span',[_vm._v(_vm._s(_vm.$trans('no_free_hours')))])])],1),(_vm.firstAvailableDate)?_c('v-row',[_c('v-col',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$trans('first_available_date'))+":"),_c('br'),_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm._f("moment")(_vm.firstAvailableDate,'dddd D MMMM')))])])],1):_vm._e(),(_vm.firstAvailableDate)?_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center"},[_c('v-btn',{attrs:{"color":"wb_color_primary__color__","depressed":""},on:{"click":function($event){return _vm.$emit('date', _vm.firstAvailableDate)}}},[_vm._v(" "+_vm._s(_vm.$trans('select_date'))+" ")])],1)],1):_vm._e()],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }